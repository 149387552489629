import { useEffect, useState } from "react";
import { LayoutProps } from "./Layout.props";
import MobileMenu from "../components/Header/MobileMenu/MobileMenu";

const Layout = ({children}: LayoutProps): JSX.Element => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 400);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <>
      {/* <Preloader loading={loading} /> */}
      <main
        id="wrapper"
        style={{ opacity: loading ? 0 : 1 }}
        className="page-wrapper animated fadeIn"
      > 
        {children}
      </main>
      <MobileMenu />
    </>
  );
};

export default Layout;
