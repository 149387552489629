import React from "react";
import { LinkContainer } from "react-router-bootstrap";
import { NavItemProps } from "./NavItem.props";
import SubItem from "../SubItem/SubItem";
import { FaAngleDown } from "react-icons/fa";
import useToggle from "../../../hooks/useToggle";
import { useRootContext } from "../../../context/RootContext";
import { useLocation } from "react-router-dom";
import { SubNavItem } from "../../../data/Menu";
import styles from './NavItem.module.css'

const NavItem = ({navItem, mobile}: NavItemProps): JSX.Element => {
  const [active, toggleActive] = useToggle(false);
  const { currentActive, toggleMenu } = useRootContext();
  const location = useLocation();

const handleToggleMenu = () => {
  if (mobile) {
    toggleMenu(false);
    document.body.classList.remove("locked");
  }
};

const handleActive = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
  e.stopPropagation();
  e.preventDefault();
  toggleActive();
};

  let current = location.pathname === navItem.href;
  if (!currentActive) {
    current = !!navItem.subNavItems?.find((item:SubNavItem) =>
      item.href === location.pathname
    );
  }
  
  return (
    <li className={`${current ? "current" : ""}`}>
      <LinkContainer onClick={handleToggleMenu} 
        to={navItem.href} 
        >
          {mobile && navItem.subNavItems?.length ? (
              <a>
                  {navItem.name}
                <button 
                  aria-label="dropdown toggler" 
                  onClick={handleActive} 
                  className={active ? "expanded" : ""}
                >
                  <FaAngleDown className={mobile ? styles.iconRed : styles.icon}/>
                </button>
              </a>
          ) : (
          <a> 
            <span className={ navItem.name.includes('pie') || navItem.name.includes('пред') ? styles.offer : ''}>
              {navItem.name} 
            </span>
            {navItem.subNavItems?.length && 
            <FaAngleDown className={mobile ? styles.iconRed : styles.icon} />}
          </a>
            )}
          
      </LinkContainer>
      <ul className={mobile && !active ? "d-none" : "d-block"}>
        {navItem.subNavItems?.map((subItem:SubNavItem, index:number) => (
          <SubItem 
            handleToggleMenu={handleToggleMenu} 
            key={index}
            subNavItem={subItem} 
            mobile={mobile}
          />
        ))}
      </ul>
    </li>
  );
};

export default NavItem
