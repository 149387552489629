import { LinkContainer } from 'react-router-bootstrap';
import { ServicesProps } from './Services.props';
import styles from './Services.module.css'
import cn from 'classnames';
import { BsPencilFill } from "react-icons/bs";
import { FaArrowRight } from "react-icons/fa";


const Services = ({serviceData}: ServicesProps): JSX.Element => {
  return (
    <>
      <section className={styles.wrapper}>
        <div className={cn("container", 'pt-5', 'pb-4')}>
          <div className={cn("row", styles.row__container)}>
            <div className="col-12">
              <div className="text-center mb-4">
                <span className={cn(styles.upper, "mil-suptitle mb-4")}>{serviceData.subTitle}</span>
                <h2 className="mil-upper mil-up">{serviceData.title}</h2>
              </div>
            </div>

            {serviceData.serviceItem.map((item, key) => (
              <div key={`services-item-${key}`} className="col-lg-4">
                <a href={item.to} className={cn(styles.service__card, "mb-4")}>
                  <div>
                    <div className="text-center">
                      <h4 className={cn(styles.upper, "mb-2")}>{item.title}</h4>
                      <div className={cn(styles.divider, "mb-3")} />
                        <p className={cn(styles.service__text)}>{item.message}</p>
                      <div className={cn(styles.button, styles.icon, styles.icon__bg)}
                      >
                        <FaArrowRight className={styles.icon__img}/>
                      </div>
                    </div>
                  </div>  
                </a>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
