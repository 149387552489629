export interface SubNavItem {
  name: string;
  href: string;
}

export interface Menu {
  lang: string;
  menu: MenuItems[]
}

export interface MenuItems {
  name: string;
  href: string;
  subNavItems?: SubNavItem[];
}

const menuItemsLv:MenuItems[] = [
    {
      name: "Sākumlapa",
      href: "/",
    },
    {
      name: "Pakalpojumi",
      href: "/services",
      subNavItems: [
        {  
          name: "Dizaina Projekts", href: "/design-project" 
        },
        { 
          name: "Projekts objektiem ar gatavo apdari", href: "/finished-project" 
        },
        { 
          name: "Fasādes dizaina projekts", href: "/facade-project" 
        },
        { 
          name: "Korpusa mēbeļu dizains", href: "/furniture-project" 
        },
        { 
          name: "Papildus Pakalpojumi", href: "/other-services" 
        },
      ],
    },
    {
      name: "Projekti",
      href: "/projects",
    },
    {
      name: "3D Tūres",
      href: "/3Dtours",
    },
    {
      name: "Kontakti",
      href: "/contacts",
    }
];

const menuItemsRu:MenuItems[] = [
  {
    name: "Домашняя",
    href: "/",
  },
  {
    name: "Услуги",
    href: "/services",
    subNavItems: [
      {  
        name: "Дизайн проект", href: "/design-project" 
      },
      { 
        name: "Проект для объектов с готовой отделкой", href: "/finished-project" 
      },
      { 
        name: "Проект дизайна фасада", href: "/facade-project" 
      },
      { 
        name: "Дизайн корпусной мебели", href: "/furniture-project" 
      },
      { 
        name: "Дополнительные услуги", href: "/other-services" 
      },
    ],
  },
  {
    name: "Проекты",
    href: "/projects",
  },
  {
    name: "3D Туры",
    href: "/3Dtours",
  },
  {
    name: "Контакты",
    href: "/contacts",
  }
];

export const menuData:Menu[] = [
  {
    lang: 'lv',
    menu: menuItemsLv
  },
  {
    lang: 'ru',
    menu: menuItemsRu
  }
]